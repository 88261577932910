(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@angular/core"), require("@angular/animations/browser"), require("single-spa-angular"), require("@angular/common/http"), require("rxjs"), require("@angular/router"), require("rxjs/operators"), require("@angular/forms"), require("@angular/cdk/overlay"), require("@angular/cdk/bidi"), require("@angular/common"), require("@angular/material/dialog"), require("@angular/cdk/portal"), require("@angular/cdk/platform"), require("@angular/cdk/scrolling"), require("@angular/material/core"), require("@angular/cdk/a11y"), require("@angular/material/tooltip"), require("@angular/cdk/observers"), require("@angular/cdk/layout"), require("@angular/material/button"), require("@angular/platform-browser/animations"), require("@angular/material/icon"), require("@angular/material/checkbox"), require("@angular/material/select"), require("@angular/material/slider"), require("@angular/material/menu"), require("@angular/platform-browser"), require("@angular/animations"), require("@angular/material/form-field"));
	else if(typeof define === 'function' && define.amd)
		define(["@angular/core", "@angular/animations/browser", "single-spa-angular", "@angular/common/http", "rxjs", "@angular/router", "rxjs/operators", "@angular/forms", "@angular/cdk/overlay", "@angular/cdk/bidi", "@angular/common", "@angular/material/dialog", "@angular/cdk/portal", "@angular/cdk/platform", "@angular/cdk/scrolling", "@angular/material/core", "@angular/cdk/a11y", "@angular/material/tooltip", "@angular/cdk/observers", "@angular/cdk/layout", "@angular/material/button", "@angular/platform-browser/animations", "@angular/material/icon", "@angular/material/checkbox", "@angular/material/select", "@angular/material/slider", "@angular/material/menu", "@angular/platform-browser", "@angular/animations", "@angular/material/form-field"], factory);
	else if(typeof exports === 'object')
		exports["nav-root"] = factory(require("@angular/core"), require("@angular/animations/browser"), require("single-spa-angular"), require("@angular/common/http"), require("rxjs"), require("@angular/router"), require("rxjs/operators"), require("@angular/forms"), require("@angular/cdk/overlay"), require("@angular/cdk/bidi"), require("@angular/common"), require("@angular/material/dialog"), require("@angular/cdk/portal"), require("@angular/cdk/platform"), require("@angular/cdk/scrolling"), require("@angular/material/core"), require("@angular/cdk/a11y"), require("@angular/material/tooltip"), require("@angular/cdk/observers"), require("@angular/cdk/layout"), require("@angular/material/button"), require("@angular/platform-browser/animations"), require("@angular/material/icon"), require("@angular/material/checkbox"), require("@angular/material/select"), require("@angular/material/slider"), require("@angular/material/menu"), require("@angular/platform-browser"), require("@angular/animations"), require("@angular/material/form-field"));
	else
		root["nav-root"] = factory(root["@angular/core"], root["@angular/animations/browser"], root["single-spa-angular"], root["@angular/common/http"], root["rxjs"], root["@angular/router"], root["rxjs/operators"], root["@angular/forms"], root["@angular/cdk/overlay"], root["@angular/cdk/bidi"], root["@angular/common"], root["@angular/material/dialog"], root["@angular/cdk/portal"], root["@angular/cdk/platform"], root["@angular/cdk/scrolling"], root["@angular/material/core"], root["@angular/cdk/a11y"], root["@angular/material/tooltip"], root["@angular/cdk/observers"], root["@angular/cdk/layout"], root["@angular/material/button"], root["@angular/platform-browser/animations"], root["@angular/material/icon"], root["@angular/material/checkbox"], root["@angular/material/select"], root["@angular/material/slider"], root["@angular/material/menu"], root["@angular/platform-browser"], root["@angular/animations"], root["@angular/material/form-field"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_vOrQ__, __WEBPACK_EXTERNAL_MODULE_v5i9__, __WEBPACK_EXTERNAL_MODULE__aQr__, __WEBPACK_EXTERNAL_MODULE_jyyq__, __WEBPACK_EXTERNAL_MODULE_Vgaj__, __WEBPACK_EXTERNAL_MODULE_JAnZ__, __WEBPACK_EXTERNAL_MODULE_aP7H__, __WEBPACK_EXTERNAL_MODULE__3xDq__, __WEBPACK_EXTERNAL_MODULE__8Yb7__, __WEBPACK_EXTERNAL_MODULE_sE19__, __WEBPACK_EXTERNAL_MODULE__0S4P__, __WEBPACK_EXTERNAL_MODULE_h8Zy__, __WEBPACK_EXTERNAL_MODULE_IGV0__, __WEBPACK_EXTERNAL_MODULE_lVjo__, __WEBPACK_EXTERNAL_MODULE__7zYK__, __WEBPACK_EXTERNAL_MODULE_c5Cn__, __WEBPACK_EXTERNAL_MODULE__7zk3__, __WEBPACK_EXTERNAL_MODULE_YYwv__, __WEBPACK_EXTERNAL_MODULE__9Ka3__, __WEBPACK_EXTERNAL_MODULE_l9L7__, __WEBPACK_EXTERNAL_MODULE_MdUB__, __WEBPACK_EXTERNAL_MODULE_Xwin__, __WEBPACK_EXTERNAL_MODULE_vbq7__, __WEBPACK_EXTERNAL_MODULE__2caw__, __WEBPACK_EXTERNAL_MODULE_L2qU__, __WEBPACK_EXTERNAL_MODULE_Q5cP__, __WEBPACK_EXTERNAL_MODULE_D7y7__, __WEBPACK_EXTERNAL_MODULE_vG_p__, __WEBPACK_EXTERNAL_MODULE__9gj6__, __WEBPACK_EXTERNAL_MODULE_EdK2__) {
return 